import React from "react";

export default function BookNow(props) {
  if(props.bookNowLink){
    return (
      <a href={props.bookNowLink} target="_blank" rel="noreferrer">
        <div className="m-auto md:w-1/3 w-full pb-4">
          <button className="m-auto border rounded-sm border-gray-400 w-full font-bold text-center md:text-xl py-4 uppercase text-lg text-gray-800 bg-white">
            Book Now
          </button>
        </div>
      </a>
    );
  }
  return <div></div>;

}

BookNow.defaultProps = {
  bookNowLink: "",
};
