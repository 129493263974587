import React from "react";
import { graphql } from "gatsby";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import SeoContent from "../../components/seoContent";
import ImageWall from "../../components/ImageWall";
import Container from "../../components/Container";
import ContainerHeader from "../../components/ContainerHeader";
import ContainerContent from "../../components/ContainerContent";
import BookNow from "../../components/BookNow";

export const query = graphql`
  query AccommocationQuery($slug: String!) {
    strapiAccommodation(Slug: {eq: $slug}) {
      RoomName
      Slug
      BookNowLink
      Images {
        id
        alternativeText
        localFile {
          childImageSharp {
            fixed (width: 800, height: 534) {
              src
            }
          }
        }
        alternativeText
      }
      MainImage {
        alternativeText
        localFile {
          childImageSharp {
            fixed (width: 1920, height: 1080) {
              src
            }
          }
        }
      }
      ShowMainImage
      BookNowLink
      Description
      Seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  }
`;

export default function Accommodation({ data }) {
  const isSingleSiteEnvSetting = process.env.IS_SINGLE_PAGE_SITE;

  const isSingleSite = isSingleSiteEnvSetting === "true";

  if(!data) return <div></div>;
  const accommodation = data.strapiAccommodation;

  const mainImage = () => {
    if(accommodation.ShowMainImage){
      return (
        <Container>
          <img
            className="w-full"
            src={accommodation.MainImage.localFile.childImageSharp.fixed.src}
            alt={accommodation.MainImage.alternativeText}
          />
        </Container>
      );
    }
  }
  return (
    <>
      <SeoContent pageSeo={accommodation.Seo}/>
      <div className="leading-relaxed bg-primary tracking-wide flex flex-col font-custom">
        <NavBar isSingleSite={isSingleSite}/>
        {mainImage()}
        <Container>
          <ContainerHeader text={accommodation.RoomName} />
          <ContainerContent text={accommodation.Description} />
          <BookNow bookNowLink={accommodation.BookNowLink} />
          <ImageWall
            images={accommodation.Images} keyPrefix="accommodation"
          />
        </Container>

        <Footer />
      </div>
    </>
  );
}
